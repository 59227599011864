@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-3xl;
        @apply text-gray-600;
        @apply font-bold;
    }
    ,
    h2 {
        @apply text-2xl;
        @apply text-gray-600;
        @apply font-bold;
    }
}

body {
    background-color: #FBF9F7;
}
